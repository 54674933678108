<template>
  <div class="mt-5">
    <span class="text--secondary note-missing"
      >*Address missing? let me know:</span
    >
    <form
      @submit.prevent="handleSubmit"
      class="mt-3"
      name="add-coin"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="add-coin" />
      <v-text-field
        style="max-width: 200px"
        name="message"
        label="Coin"
        :value="form.message"
        @input="updateText($event)"
        hint="Hint text"
      ></v-text-field>

      <v-btn color="secondary" type="submit" :disabled="form.message === ''"
        >Send</v-btn
      >
    </form>
    <v-snackbar v-model="snackbar">
      Form sent!

      <template v-slot:action="{ attrs }">
        <v-btn color="red" v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "QAForm",
  data() {
    return {
      form: {
        message: "",
      },
      snackbar: false,
    };
  },
  methods: {
    updateText(e) {
      this.form.message = e;
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    handleSubmit() {
      const axiosConfig = {
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      };
      axios.post(
        "/",
        this.encode({
          "form-name": "add-coin",
          ...this.form,
        }),
        axiosConfig
      );
      const encoded = this.encode({
        "form-name": "add-coin",
        ...this.form,
      });
      this.snackbar = true;
    },
  },
};
</script>

<style>
form {
  display: flex;
  align-items: stretch;
}
div.v-input {
  margin-top: 0;
  padding-top: 0;
}
input[type="text"] {
  max-height: 34px;
}
.v-input__slot {
  margin-bottom: 0;
}
.v-text-field__details {
  display: none;
}
input[type="text"],
label {
  padding-left: 10px;
  padding-right: 10px;
}
.v-btn {
  margin-left: 10px;
}
.note-missing {
  font-size: 12px;
  line-height: normal;
}
</style>
