<template>
  <v-app>
    <div class="pt-8">
      <v-app-bar dense fixed>
        <div
          class="d-flex align-center justify-space-between"
          style="width: 100%"
        >
          <Nftify />
          <v-switch
            class="mt-2 mode-switch"
            :label="$vuetify.theme.dark ? 'Dark Mode' : 'Light Mode'"
            v-model="$vuetify.theme.dark"
            @change="handleThemeChange($event)"
          ></v-switch>
        </div>
      </v-app-bar>
      <v-main class="container">
        <v-container>
          <Intro />
          <Table />
          <Form />
          <p
            class="note-light px-2 py-2 mt-5 text-subtitle-2"
            :class="{ 'note-dark': $vuetify.theme.dark }"
          >
            * I created this project on 26 Oct 2021, It took me a day to build
            it, and people are already using it. I am very happy to hear that.
            If you have some ideas or there is anything you want to talk about,
            reach me out on
            <a
              title="@unlockiest twitter"
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/unlockiest"
              >twitter</a
            >
          </p>
          <div
            class="note-light px-4 py-4 mt-5 text-subtitle-2 text-center"
            v-if="this.advNumber === 0"
            :class="{ 'note-dark': $vuetify.theme.dark }"
          >
            <span class="d-block mb-2"
              >* NFTify has nice referral program, and I want this website to be
              self sustained, so go ahead and:</span
            >
            <Nftify class="mb-3" title="Sell your NFT's on NFTify" />
            <span class="d-flex align-center justify-center"
              >Supports:
              <img
                :src="require(`@/assets/icons/eth.svg`)"
                alt="eth"
                style="width: 25px; height: 25px"
                class="mx-1"
              />
              <img
                :src="require(`@/assets/icons/matic.svg`)"
                alt="matic"
                style="width: 25px; height: 25px"
                class="mx-1"
              />
              <img
                :src="require(`@/assets/icons/bnb.svg`)"
                alt="bnb"
                style="width: 25px; height: 25px"
                class="mx-1"
              />
            </span>
          </div>

          <!--          <div
            class="note-light px-4 py-4 mt-5 text-subtitle-2 text-center"
            :class="{ 'note-dark': $vuetify.theme.dark }"
          >
            <span class="d-block mb-3"
              >To make everyone's life easier I built universal NFT Explorer.
              Use one tool instead of multiple:</span
            >
          </div>-->
        </v-container>
      </v-main>
      <v-footer class="px-5 py-2">
        <!--      <h6 class="h6">I literally wrote this project in one day.</h6>-->
        <span class="note-follow">
          Follow on Twitter:
          <v-btn
            style="margin-left: 0"
            target="_blank"
            icon
            rel="noreferrer"
            aria-label="Twitter Unlockiest"
            href="https://twitter.com/unlockiest"
            ><v-icon>mdi-twitter</v-icon></v-btn
          >
        </span>
      </v-footer>
    </div>
  </v-app>
</template>

<script>
import Table from "./components/Table";
import Intro from "./components/Intro";
import Form from "./components/Form";
import Nftify from "./components/Nftify";

export default {
  name: "App",
  components: {
    Nftify,
    Table,
    Intro,
    Form,
  },
  computed: {
    advNumber() {
      return this.getRandomInt(2); // => 0 or 1
    },
  },
  methods: {
    handleThemeChange(boolean) {
      window.localStorage.setItem("burnyournftsdark", boolean);
    },
    // starts from 0 up to max - 1
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
  },
  created() {
    const item = window.localStorage.getItem("burnyournftsdark");
    const dark = item === "true";
    if (item) {
      this.$vuetify.theme.dark = !!dark;
    }
    //fallback if no localstorage
    else {
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? (this.$vuetify.theme.dark = true)
        : (this.$vuetify.theme.dark = false);
    }
  },
};
</script>

<style>
.v-input__control .v-messages {
  display: none;
}
.note-follow {
  font-size: 12px;
  line-height: normal;
}
.note-light {
}
.note-dark {
  background-color: #1e1e1e;
}
.mode-switch label {
  display: none !important;
}
@media (min-width: 600px) {
  .mode-switch label {
    display: inline !important;
  }
}
</style>
