<template>
  <div>
    <h1 class="h1 text-center mb-5 mt-5">🔥 Get rid of your NFT's</h1>
    <p class="p text-center">
      This website provides addresses for different chains to send your unused
      and unwanted NFT's.
    </p>
    <p class="p text-center mb-10">
      Simply send token to the address and forget about it.
    </p>
  </div>
</template>

<script>
export default {
  name: "Intro",
};
</script>

<style scoped></style>
