<template>
  <v-btn
    color="primary"
    :href="url"
    target="_blank"
    @click.prevent="handleClick()"
  >
    <span class="d-none d-sm-inline">{{ title }}</span>
    <span class="d-inline d-sm-none">{{ title.replace("Sell on ", "") }}</span>
  </v-btn>
</template>

<script>
export default {
  name: "nftify",
  props: {
    title: {
      type: String,
      default: "Sell on NFTify",
    },
  },
  data: function () {
    return {
      url: "https://app.nftify.network/register?referralCode=EvTzV59",
    };
  },
  methods: {
    handleClick() {
      this.$gtag.event("click", {
        event_category: "outbound",
        event_label: this.url,
        transport_type: "beacon",
        event_callback: () => {
          window.open(this.url);
        },
      });
      window.open(this.url);
    },
  },
};
</script>

<style scoped></style>
