var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"pt-3",attrs:{"items-per-page":"20","headers":_vm.headers,"search":_vm.search,"items":_vm.items,"hide-default-footer":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('img',{staticStyle:{"width":"25px","height":"25px"},attrs:{"src":require(("@/assets/icons/" + (item.icon) + ".svg")),"alt":item.icon}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(item.name))])])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.copyAddress(item.address)}}},[_vm._v(_vm._s(_vm._f("truncateAddress")(item.address)))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"5px"},attrs:{"icon":"","aria-label":("Copy " + (item.icon) + " address")},on:{"click":function($event){return _vm.copyAddress(item.address)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy address")])])],1)]}},{key:"item.sent",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.sent))])]}}],null,true)},[_c('span',[_vm._v("Sent "+_vm._s(item.sent)+" nfts. "),_c('br'),_vm._v(" Last updated: 29 December 2023")])])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"href":item.link,"icon":"","target":"_blank"}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}])}),_c('v-snackbar',{attrs:{"timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',{staticClass:"green--text"},[_vm._v("Address copied!")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }