<template>
  <div>
    <v-data-table
      items-per-page="20"
      :headers="headers"
      :search="search"
      :items="items"
      class="pt-3"
      :hide-default-footer="true"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.name="{ item }">
        <div class="d-flex align-center">
          <img
            :src="require(`@/assets/icons/${item.icon}.svg`)"
            :alt="item.icon"
            style="width: 25px; height: 25px"
          />
          <span style="margin-left: 10px">{{ item.name }}</span>
        </div>
      </template>

      <template v-slot:item.address="{ item }">
        <div class="d-flex align-center">
          <span style="cursor: pointer" @click="copyAddress(item.address)">{{
            item.address | truncateAddress
          }}</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :aria-label="`Copy ${item.icon} address`"
                @click="copyAddress(item.address)"
                style="margin-left: 5px"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Copy address</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.sent="{ item }">
        <v-tooltip top max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.sent }}</span>
          </template>
          <span
            >Sent {{ item.sent }} nfts. <br />
            Last updated: 29 December 2023</span
          >
        </v-tooltip>
      </template>
      <template v-slot:item.link="{ item }">
        <v-btn :href="item.link" icon target="_blank">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" timeout="3000">
      <span class="green--text">Address copied!</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      snackbar: false,
      items: [
        {
          name: "Ethereum",
          icon: "eth",
          address: "0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
          sent: "🔥 386",
          chain: "eth",
          link: "https://dappradar.com/hub/wallet/eth/0xfea49124b05b61337dc0aa7e1af316ef0da24d3a/nfts",
        },
        {
          name: "Solana",
          icon: "sol",
          address: "6t15n9xHdt1uZg6mYxCZdE6uhiBhDqKn7puXQMA4k8XV",
          sent: "🔥 1630",
          chain: "sol",
          link: "https://solscan.io/assets/6t15n9xHdt1uZg6mYxCZdE6uhiBhDqKn7puXQMA4k8XV",
        },
        {
          name: "Polygon",
          icon: "matic",
          address: "0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
          sent: "🔥 1697",
          chain: "matic",
          link: "https://dappradar.com/hub/wallet/polygon/0xfea49124b05b61337dc0aa7e1af316ef0da24d3a/nfts",
        },
        {
          name: "Binance (Smart Chain)",
          icon: "bnb",
          address: "0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
          sent: "🔥 15",
          chain: "bsc",
          link: "https://bnb.nftscan.com/0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
        },
        {
          name: "Cardano",
          icon: "ada",
          address:
            "addr1qy9rayce287p7zuy95wjm09kp3tkjsdjecr2d7c4wjj5j83llg0lel044zhcj2kgsluj8twzc9zhwetuejuqqt0gnuuqgyrsxx",
          sent: "🔥 101",
          chain: "ada",
          link: "https://cexplorer.io/address/addr1qy9rayce287p7zuy95wjm09kp3tkjsdjecr2d7c4wjj5j83llg0lel044zhcj2kgsluj8twzc9zhwetuejuqqt0gnuuqgyrsxx/asset#data",
        },
        {
          name: "Tezos",
          icon: "xtz",
          address: "tz1aih33YJPLYqpSosdoNtCC3fPSSFWZwNxo",
          sent: "🔥 20",
          chain: "xtz",
          link: "https://tzstats.com/tz1aih33YJPLYqpSosdoNtCC3fPSSFWZwNxo#nfts",
        },
        {
          name: "Stacks",
          icon: "stx",
          address: "SP3FTKFY3DTGNGBRXAQZDZE5D8YZ76K7JCVCQBMHX",
          sent: "🔥 31",
          chain: "stx",
          link: "https://explorer.hiro.so/address/SP3FTKFY3DTGNGBRXAQZDZE5D8YZ76K7JCVCQBMHX?chain=mainnet",
        },
        {
          name: "Avalanche",
          icon: "avax",
          address: "0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
          sent: "🔥 320",
          chain: "avalanche",
          link: "https://avascan.info/blockchain/all/address/0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
        },
        {
          name: "Fantom",
          icon: "ftm",
          address: "0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
          sent: "🔥 22",
          chain: "ftm",
          link: "https://ftmscan.com/tokenholdings?a=0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
        },
        {
          name: "Arbitrum",
          icon: "arbitrum",
          address: "0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
          sent: "🔥 1",
          chain: "arbitrum",
          link: "https://arbiscan.io/address/0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a#tokentxnsErc1155",
        },
        {
          name: "Optimism",
          icon: "optimism",
          address: "0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
          sent: "🔥 10",
          chain: "optimism",
          link: "https://optimistic.etherscan.io/address/0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a#tokentxnsErc721",
        },
        {
          name: "Moonbeam",
          icon: "moonbeam",
          address: "0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
          sent: "🔥 0",
          chain: "moonbeam",
          link: "https://moonscan.io/address/0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a#tokentxns",
        },
        {
          name: "PlatON",
          icon: "platon",
          address: "0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
          sent: "🔥 0",
          chain: "platon",
          link: "https://scan.platon.network/address-detail?address=0xfea49124b05b61337dc0aa7e1af316ef0da24d3a",
        },
        {
          name: "Cronos",
          icon: "cro",
          address: "0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
          sent: "🔥 0",
          chain: "cro",
          link: "https://cronoscan.com/address/0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a#tokentxns",
        },
        {
          name: "Gnosis",
          icon: "gno",
          address: "0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
          sent: "🔥 0",
          chain: "gno",
          link: "https://gnosisscan.io/tokenholdings?a=0xFEa49124b05B61337dC0AA7E1af316ef0DA24d3a",
        },
      ],
      headers: [
        {
          text: "Coin",
          align: "start",
          value: "name",
          sortable: true,
        },
        { text: "", value: "icon", sortable: false },
        { text: "Address", value: "address", sortable: false },
        { text: "Sent", value: "sent", sortable: false },
        { text: "Link", value: "link", sortable: false },
      ],
    };
  },
  methods: {
    copyAddress(address) {
      const el = document.createElement("textarea");
      el.value = address;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
        this.snackbar = true;
      }
    },
  },
  filters: {
    truncateAddress: function (value) {
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      // if length > 45 split
      // 75...15
      value = value.toString();
      if (isMobile) {
        return value.length > 25
          ? `${value.substring(0, 10)}...${value.substr(value.length - 10)}`
          : value;
      } else {
        return value.length > 45
          ? `${value.substring(0, 25)}...${value.substr(value.length - 15)}`
          : value;
      }
    },
  },
};
</script>
