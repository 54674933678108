import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: {
    id: "G-T4PBPVMKYM",
  },
});

Vue.use(Vuetify);

export default new Vuetify({});
